<div class="w-100 justify-between">
  <button
    type="button"
    class="link-button"
    (click)="clear.emit()"
  >
    Clear Filters
  </button>

  <button
    mat-stroked-button
    class="button-stroked-base"
    [color]="'primary'"
    (click)="confirm.emit()"
  >
    Search Filters
  </button>
</div>