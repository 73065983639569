<app-roam-dropdown-button
  #dropdownComponent
  [color]="(selected.from || selected.to) ? 'primary' : null"
  [label]="label"
>
  <ng-container
    dropdown-content
    *ngTemplateOutlet="dropdownContentTemplate"
  />
</app-roam-dropdown-button>

<ng-template #dropdownContentTemplate>
  <div class="dropdown-content-container">
    <div class="d-grid gap-1rem px-1rem">
      <app-roam-select
        [placeholder]="'Select Period'"
        [items]="datePeriodsOptions"
        (selectedItem)="changePeriod($event)"
      />

      <app-roam-input-group-from-to
        [valueFrom]="temporary.from"
        [valueTo]="temporary.to"
        (valueFromChange)="updateValueFrom($event)"
        (valueToChange)="updateValueTo($event)"
      />
    </div>

    <hr class="my-0" />

    <app-roam-filter-footer-button
      class="px-1rem"
      (clear)="clear()"
      (confirm)="confirm()"
    />
  </div>
</ng-template>
