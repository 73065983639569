
  export const enumKey = 
  <T extends Record<string, string | number>>(value: T[keyof T], enums: T): keyof T | undefined => {
    return (Object.entries(enums) as [keyof T, T[keyof T]][])
      .find(([_, enumValue]) => enumValue === value)?.[0];
  };

  export const enumToArray = (enumObj: any): { key: string, id: string, name: string }[] => {
    return Object.keys(enumObj).map(key => ({
      key: key,                   // This is the enum's key, useful for comparisons or as identifiers
      id: enumObj[key],           // The enum's value, could be used as a unique identifier
      name: enumObj[key]          // The friendly name to display in UI components
    }));
  }
