<app-roam-dropdown-button
  #dropdownComponent
  [color]="text ? 'primary' : null"
  [label]="label"
>
  <ng-container
    dropdown-content
    *ngTemplateOutlet="dropdownContentTemplate"
  />
</app-roam-dropdown-button>

<ng-template #dropdownContentTemplate>
  <div class="dropdown-content-container">
    <div class="px-1rem py-8px">
      <app-roam-input-aq
        class="w-100"
        [label]="label"
        [value]="temporary"
        (valueChange)="textChange($event)"
      />
    </div>

    <hr class="my-0" />

    <app-roam-filter-footer-button
      class="px-1rem"
      (clear)="clear()"
      (confirm)="confirm()"
    />
  </div>
</ng-template>