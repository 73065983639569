import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { RoamDropdownButtonComponent } from "@shared-components/roam-dropdown-button";
import { Subscription } from "rxjs";
import { DatePeriods } from "../../../core/const/date-periods";
import { RoamFilterFooterButtonComponent } from "../roam-filter-footer-button";
import { RoamInputGroupFromToComponent } from "../roam-input-group-from-to";
import { RoamSelectComponent } from "../roam-select/roam-select.component";
import { getDateRange } from "@app/core/helper/date-range-filter";
import { format } from "date-fns";
import { enumToArray } from "@app/core/helper/enum.helper";

@Component({
  selector: "app-roam-filter-dropdown-from-to",
  templateUrl: "./roam-filter-dropdown-from-to.component.html",
  styleUrl: "./roam-filter-dropdown-from-to.component.scss",
  standalone: true,
  imports: [
    CommonModule,
    RoamDropdownButtonComponent,
    RoamFilterFooterButtonComponent,
    RoamInputGroupFromToComponent,
    RoamSelectComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RoamFilterDropdownFromToComponent
  implements AfterViewInit, OnDestroy
{
  @Input({ required: true }) label!: string;

  @Input("valueFrom") set setValueFrom(from: null | string) {
    this.selected.from = from;
  }

  @Input("valueTo") set setValueTo(to: null | string) {
    this.selected.to = to;
  }

  @Output() fromChange: EventEmitter<string | null> = new EventEmitter();

  @Output() toChange: EventEmitter<string | null> = new EventEmitter();

  @ViewChild("dropdownComponent")
  dropdownComponent!: RoamDropdownButtonComponent;

  private subscription?: Subscription;

  selected: {
    from: null | string;
    to: null | string;
  } = {
    from: null,
    to: null,
  };
  temporary: {
    from: null | string;
    to: null | string;
  } = {
    from: null,
    to: null,
  };

  datePeriodsOptions: { key: string; id: string; name: string }[] = enumToArray(DatePeriods);

  ngAfterViewInit() {
    this.subscription =
      this.dropdownComponent.dropdownTrigger.menuOpened.subscribe(
        () => (this.temporary = { ...this.selected })
      );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  updateValueFrom(from: string | null) {
    this.temporary.from = from;
  }

  updateValueTo(to: string | null) {
    this.temporary.to = to;
  }

  clear() {
    this.temporary = { from: null, to: null };
  }

  changePeriod(period: keyof typeof DatePeriods): void {
    const { startDate, endDate } = getDateRange(period);
  
    this.temporary.from = startDate as any
    this.temporary.to = endDate as any  
  }
  
  confirm() {
    this.selected = { ...this.temporary };
    console.log(this.selected);
    
    this.dropdownComponent.close();
    this.fromChange.emit(this.selected.from);
    this.toChange.emit(this.selected.to);
  }
}
