import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-roam-input-aq",
  templateUrl: "./roam-input-aq.component.html",
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule],
})
export class RoamInputAQComponent {
  @Input() class?: string;

  @Input({ required: true }) label: string = "Input";

  @Input() value?: string | number | null;

  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  temporary!: string;
}
