<mat-form-field
  appearance="outline"
  [class]="'roam-field white-bg ' + class"
  >
  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    [value]="value"
    [(ngModel)]="temporary"
    (change)="valueChange.emit(temporary)"
  />
</mat-form-field>