<div class="align-center justify-between gap-8px">
  <app-roam-datepicker name="From" 
    [(ngModel)]="valueFrom"
  />
  
  <!-- <app-roam-datepicker
    [class]="isSizeSmall ?'small-width' : ''"
    [value]="valueFrom"
    (valueChange)="valueFromChange.emit($event)"
  /> -->

  <span>to</span>

  <app-roam-datepicker name="To"
    [(ngModel)]="valueTo" />

  <!-- <app-roam-datepicker
    [class]="isSizeSmall ?'small-width' : ''"
    [label]="'To'"
    [value]="valueTo"
    (valueChange)="valueToChange.emit($event)"
  /> -->
</div>