import { Component, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "app-roam-dropdown-button",
  templateUrl: "./roam-dropdown-button.component.html",
  styleUrl: "./roam-dropdown-button.component.scss",
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatMenuModule],
  encapsulation: ViewEncapsulation.None,
})
export class RoamDropdownButtonComponent {
  @Input() badge?: number;

  @Input() color?: null | "primary";

  @Input() icon?: string = "keyboard_arrow_down";

  @Input() disabled?: boolean = false;

  @Input({ required: true }) label: string = "Dropdown";

  @ViewChild(MatMenuTrigger) dropdownTrigger!: MatMenuTrigger;

  close() {
    this.dropdownTrigger.closeMenu();
  }
}
