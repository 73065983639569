import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RoamDatepickerComponent } from "../roam-datepicker/roam-datepicker.component";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-roam-input-group-from-to",
  templateUrl: "./roam-input-group-from-to.component.html",
  standalone: true,
  imports: [CommonModule, RoamDatepickerComponent, FormsModule],
})
export class RoamInputGroupFromToComponent {
  @Input() valueFrom?: string | null | Date;

  @Input() valueTo?: string | null | Date;

  @Output() valueFromChange: EventEmitter<string | null> = new EventEmitter();

  @Output() valueToChange: EventEmitter<string | null> = new EventEmitter();
}
