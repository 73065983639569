<button
  [disabled]="disabled"
  mat-stroked-button
  class="roam-dropdown-button button-stroked-rounded text-nowrap"
  #menuTrigger="matMenuTrigger"
  [color]="color"
  [matMenuTriggerFor]="menu">
  <div class="dropdown-button-wrapper" [class]="badge ? 'gap-6px' : 'gap-12px'">
    <p class="align-center gap-4px">
      <span>{{ label }}</span>

      @if (badge) {
        <span class="dropdown-button-badge">{{ badge }}</span>
      }
    </p>

    <mat-icon class="dropdown-button-icon">{{ icon }}</mat-icon>
  </div>
</button>

<mat-menu class="dropdown-content-wrapper" #menu="matMenu">
  <div (click)="$event.stopPropagation()">
    <ng-content select="[dropdown-content]"></ng-content>
  </div>
</mat-menu>
