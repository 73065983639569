import {
  addDays,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";
import { DatePeriods } from "../const/date-periods";

export const getDateRange = (
  period: keyof typeof DatePeriods
): { startDate: Date; endDate: Date } => {
  const today = new Date();

  let startDate: Date;
  let endDate: Date = startOfDay(today);

  switch (DatePeriods[period]) {
    case DatePeriods.TODAY:
      startDate = startOfDay(today);
      break;
    case DatePeriods.THIS_WEEK:
      startDate = startOfWeek(today);
      break;
    case DatePeriods.THIS_MONTH:
      startDate = startOfMonth(today);
      endDate = endOfMonth(today);
      break;
    case DatePeriods.THIS_WEEK_TO_DATE:
      startDate = startOfWeek(addDays(today, 1));
      break;
    case DatePeriods.THIS_MONTH_TO_DATE:
      startDate = startOfMonth(today);
      break;
    case DatePeriods.THIS_QUARTER:
      startDate = startOfQuarter(today);
      endDate = endOfQuarter(today);
      break;
    case DatePeriods.THIS_QUARTER_TO_DATE:
      startDate = startOfQuarter(today);
      break;
    case DatePeriods.THIS_YEAR_TO_DATE:
      startDate = startOfYear(today);
      break;
    case DatePeriods.THIS_YEAR_TO_LAST_MONTH:
      startDate = startOfYear(today);
      endDate = endOfMonth(subMonths(today, 1));
      break;
    case DatePeriods.YESTERDAY:
      startDate = startOfDay(subDays(today, 1));
      endDate = startOfDay(subDays(today, 1));
      break;
    case DatePeriods.LAST_WEEK:
      startDate = startOfWeek(subDays(today, 7));
      endDate = subDays(startOfWeek(today), 1);
      break;
    case DatePeriods.LAST_WEEK_TO_DATE:
      startDate = startOfWeek(subDays(today, 7));
      break;
    case DatePeriods.LAST_MONTH:
      startDate = startOfMonth(subMonths(today, 1));
      endDate = subDays(startOfMonth(today), 1);
      break;
    case DatePeriods.LAST_MONTH_TO_DATE:
      startDate = startOfMonth(subMonths(today, 1));
      break;
    case DatePeriods.LAST_QUARTER:
      startDate = startOfQuarter(subQuarters(today, 1));
      endDate = subDays(startOfQuarter(today), 1);
      break;
    case DatePeriods.LAST_QUARTER_TO_DATE:
      startDate = startOfQuarter(subQuarters(today, 1));
      break;
    case DatePeriods.LAST_YEAR:
      startDate = startOfYear(subYears(today, 1));
      endDate = subDays(startOfYear(today), 1);
      break;
    case DatePeriods.NEXT_7_DAYS:
      endDate = addDays(today, 7);
      startDate = today;
      break;
    case DatePeriods.NEXT_30_DAYS:
      endDate = addDays(today, 30);
      startDate = today;
      break;
    case DatePeriods.NEXT_60_DAYS:
      endDate = addDays(today, 60);
      startDate = today;
      break;
    case DatePeriods.NEXT_90_DAYS:
      endDate = addDays(today, 90);
      startDate = today;
      break;
    case DatePeriods.NEXT_120_DAYS:
      endDate = addDays(today, 120);
      startDate = today;
      break;
    case DatePeriods.LAST_7_DAYS:
      startDate = subDays(today, 7);
      break;
    case DatePeriods.LAST_30_DAYS:
      startDate = subDays(today, 30);
      break;
    case DatePeriods.LAST_60_DAYS:
      startDate = subDays(today, 60);
      break;
    case DatePeriods.LAST_60_DAYS:
      startDate = subDays(today, 60);
      break;
    case DatePeriods.LAST_90_DAYS:
      startDate = subDays(today, 90);
      break;
    case DatePeriods.LAST_120_DAYS:
      startDate = subDays(today, 120);
      break;
    default:
      startDate = startOfDay(today);
      break;
  }

  return { startDate, endDate };
};
