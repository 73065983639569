export enum DatePeriods {
  ALL_TIME ='All Time',
  TODAY = 'Today',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  THIS_WEEK_TO_DATE = 'This Week to Date',
  THIS_MONTH_TO_DATE = 'This Month to Date',
  THIS_QUARTER = 'This Quarter',
  THIS_QUARTER_TO_DATE = 'This Quarter to Date',
  THIS_YEAR_TO_DATE = 'This Year to Date',
  THIS_YEAR_TO_LAST_MONTH = 'This Year to Last Month',
  YESTERDAY = 'Yesterday',
  LAST_WEEK = 'Last Week',
  LAST_WEEK_TO_DATE = 'Last Week to Date',
  LAST_MONTH = 'Last Month',
  LAST_MONTH_TO_DATE = 'Last Month to Date',
  LAST_QUARTER = 'Last Quarter',
  LAST_QUARTER_TO_DATE = 'Last Quarter to Date',
  LAST_YEAR = 'Last Year',
  NEXT_7_DAYS = 'Next 7 days',
  NEXT_30_DAYS = 'Next 30 days',
  NEXT_60_DAYS = 'Next 60 days',
  NEXT_90_DAYS = 'Next 90 days',
  NEXT_120_DAYS = 'Next 120 days',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  LAST_60_DAYS = 'Last 60 days',
  LAST_90_DAYS = 'Last 90 days',
  LAST_120_DAYS = 'Last 120 days'
}
