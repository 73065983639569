import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { InputSearchOneComponent } from "@shared-components/input/input-search-one/input-search-one.component";
import { RoamDropdownButtonComponent } from "@shared-components/roam-dropdown-button";
import { Subscription } from "rxjs";
import { RoamFilterFooterButtonComponent } from "../roam-filter-footer-button";
import { RoamInputAQComponent } from "../roam-input-aq";

@Component({
  selector: "app-roam-filter-dropdown-text",
  templateUrl: "./roam-filter-dropdown-text.component.html",
  styleUrl: "./roam-filter-dropdown-text.component.scss",
  standalone: true,
  imports: [
    CommonModule,
    InputSearchOneComponent,
    RoamDropdownButtonComponent,
    RoamInputAQComponent,
    RoamFilterFooterButtonComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RoamFilterDropdownTextComponent
  implements AfterViewInit, OnDestroy
{
  @Input({ required: true }) label!: string;

  @Input("value") set setValue(value: null | string) {
    this.text = value;
  }

  @Output() change: EventEmitter<null | string> = new EventEmitter();

  @ViewChild("dropdownComponent")
  dropdownComponent!: RoamDropdownButtonComponent;

  private subscription?: Subscription;

  temporary: null | string = "";
  text: null | string = "";

  ngAfterViewInit() {
    this.subscription =
      this.dropdownComponent.dropdownTrigger.menuOpened.subscribe(
        () => (this.temporary = this.text)
      );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  textChange(text: string) {
    this.temporary = text;
  }

  clear() {
    console.log("clear");

    this.temporary = "";
  }

  confirm() {
    this.text = this.temporary;
    this.dropdownComponent.close();
    this.change.emit(this.text);
  }
}
