import { Component, EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-roam-filter-footer-button",
  templateUrl: "./roam-filter-footer-button.component.html",
  standalone: true,
  imports: [MatButtonModule],
})
export class RoamFilterFooterButtonComponent {
  @Output() clear: EventEmitter<null> = new EventEmitter();
  @Output() confirm: EventEmitter<null> = new EventEmitter();
}
